import React, { useState, lazy, Suspense } from 'react';
import { HashRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { MainContext } from './ContextStore/MainContext';

const SampleAboutUs = lazy(() => import('./Screens/SampleAboutUs/SampleAboutUs'));
const SampleContactUs = lazy(() => import('./Screens/SampleContactUs/SampleContactUs'));
const SampleReservations = lazy(() => import('./Screens/SampleReservations/SampleReservations'));
const SampleLanding = lazy(() => import('./Screens/SampleHome/SampleLanding/SampleLanding'));
const SampleExplore = lazy(() => import('./Screens/SampleExplore/SampleExplore'));
const ReservationImages = lazy(() => import('./Screens/ReservationImages/ReservationImages'));

function App() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDate2, setSelectedDate2] = useState(new Date());
  const [adults, setAdults] = useState('Select');
  const [children, setChildrens] = useState('Select');
  const [roomTypeVal, setRoomTypeVal] = useState('Select');
  const [roomTypeData, setRoomTypeData] = useState([]);
  const [branchLocation, setBranchLocation] = useState('Select');
  const [facilitiesValues, setFacilitiesValues] = useState([]);
  const [amenitiesValues, setAmenitiesValues] = useState([]);


  return (
    <div className="App" style={{ width: "95vw", overflowX: "hidden", marginLeft: "2vw" }}>
      <MainContext.Provider value={{
        selectedDate, setSelectedDate,
        selectedDate2, setSelectedDate2,
        adults, setAdults,
        children, setChildrens,
        roomTypeVal, setRoomTypeVal,
        roomTypeData, setRoomTypeData,
        branchLocation, setBranchLocation,
        facilitiesValues, setFacilitiesValues,
        amenitiesValues, setAmenitiesValues
      }}>
        <Router>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<SampleLanding />} />
              <Route path="/Home" element={<SampleLanding />} />
              <Route path="/Explore" element={<SampleExplore />} />
              <Route path="/Reservation" element={<SampleReservations />} />
              <Route path="/AboutUs" element={<SampleAboutUs />} />
              <Route path="/ContactUs" element={<SampleContactUs />} />
              <Route path="/ReservationImages" element={<ReservationImages />} />
            </Routes>
          </Suspense>
        </Router>
      </MainContext.Provider>
    </div>
  );
}

export default App;
